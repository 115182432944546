import React from 'react';
import ReactDOM from 'react-dom';
import './index.less';
import App from './App';

ReactDOM.render(<App />, document.getElementById('root'));

/* eslint-disable  */
if ('serviceWorker' in navigator && process.env.NODE_ENV === 'production') {
  window.addEventListener('load', function() {
    navigator.serviceWorker
      .register(`${process.env.PUBLIC_URL}/service-worker.js`)
      .then(function(registration) {
        if (registration.waiting && registration.active) {
          console.log('New updates have been installed. Restarting your app is recommended.');
        } else {
          registration.addEventListener('updatefound', () => {
            registration.installing.addEventListener('statechange', () => {
              if (event.target.state === 'installed') {
                if (registration.active) {
                  console.log(
                    'New updates have been installed. Restarting your app is recommended.'
                  );
                } else {
                  console.log('Content is cached for the first time!');
                }
              }
            });
          });
        }
      });
  });
}
