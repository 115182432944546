import React from 'react';
import { Card, Typography } from 'antd';
import './Home.less';

const { Meta } = Card;
const { Title } = Typography;

function WLHome() {
  const samples = [
    { title: 'Will you marry me?', url: '../../samples/gold.png' },
    { title: 'First song when we met', url: '../../samples/teal-pattern.png' },
    { title: "Your baby's first hearbeat", url: '../../samples/grey-pattern.png' },
    { title: 'I Love You!', url: '../../samples/roses.png' },
    { title: 'Record your wishes which will remain forever.', url: '../../samples/teal.png' },
    {
      title: "'I Love you dad' - from your daughters",
      url: '../../samples/grey.png'
    },
    { title: 'Your song with your picture in the background "coming soon"!', url: '../../samples/couple.png' }
  ];
  return (
    <div className="home">
      <Title level={1}>Welcome to Wave-Art</Title>
      <Title level={3}>
        Create a personalized soundwave art with your voice, song or other audio. Check out our
        samples and create yours
      </Title>
      <div className="sample-wrapper">
        {samples.map(sample => (
          <Card hoverable cover={<img alt="couple" src={sample.url} />} key={sample.title}>
            <Meta title={sample.title} />
          </Card>
        ))}
      </div>
    </div>
  );
}

export default WLHome;
