import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';

import { Menu, Dropdown, Layout, Avatar, Button, Tooltip } from 'antd';

import logo from './logo.svg';
import './Header.less';

const { Header } = Layout;

function WLHeader({ ...props }) {
  const [isLoggedIn, setIsLoggedIn] = useState();
  const [userInfo, setUserInfo] = useState();
  const login = () => {
    setUserInfo({});
    setIsLoggedIn(false);
  };
  const logout = () => {
    setUserInfo({});
    setIsLoggedIn(false);
  };
  const menu = (
    <Menu>
      <Menu.Item>
        <Button type="link" onClick={() => logout()}>
          Logout
        </Button>
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    setUserInfo({});
    setIsLoggedIn(false);
  }, []);
  return (
    <Header>
      <div className="left">
        <img src={logo} className="logo" alt="WaveArt" />
        <Menu mode="horizontal" selectedKeys={[props.location.pathname]}>
          <Menu.Item key="/">
            <Link to="/">Home</Link>
          </Menu.Item>
          <Menu.Item key="/create">
            <Link to="/create">Create</Link>
          </Menu.Item>
        </Menu>
      </div>
      <div className="right">
        {isLoggedIn ? (
          !!userInfo && (
            <Dropdown overlay={menu} placement="bottomRight">
              <Avatar src={userInfo.profile_picture} />
            </Dropdown>
          )
        ) : (
          <Tooltip placement="left" title="Click to login(coming soon)">
            <Button type="link" shape="circle" icon="login" onClick={() => login()}></Button>
          </Tooltip>
        )}
      </div>
    </Header>
  );
}

export default withRouter(WLHeader);
