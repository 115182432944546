import React, {Component} from 'react'
import {HashRouter as Router, Route} from 'react-router-dom'

import {Layout, Button} from 'antd'
import './App.less'
import Header from './components/shared/header/Header'
import WLHome from './components/routes/home/Home'
import WLCreate from './components/routes/create/Create'

const {Content, Footer} = Layout

class App extends Component {
  render() {
    return (
      <Router>
        <Layout>
          <Header></Header>
          <Content>
            <div className="app-wrapper">
              <Route name="create" path="/create" component={WLCreate} />
              <Route name="home" path="/" exact component={WLHome} />
            </div>
          </Content>
          <Footer>
            WaveArt ©2020 by
            <Button type="link" href="https://me.hemanth-anumolu.com" target="_blank">
              hem
            </Button>
          </Footer>
        </Layout>
      </Router>
    )
  }
}

export default App
