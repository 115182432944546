import React, { useState } from 'react';
import WaveSurfer from '../../../wavesurfer.js';
import { Steps, Upload, Icon, Alert, Spin, Button } from 'antd';

import './Create.less';

const { Step } = Steps;
const { Dragger } = Upload;
let file = [];

function WLCreate() {
  const [fullscreen, setFullscreen] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState(null);
  const [filename, setFilename] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [backgrounds, setBackgrounds] = useState([
    {
      url: '../../backgrounds/blue-green.jpeg',
      width: 2250,
      active: true
    },
    {
      url: '../../backgrounds/cloudy-pink.jpeg',
      width: 2250,
      active: false
    },
    // {
    //   url: '../../backgrounds/gold-foil.jpeg',
    //   width: 4500,
    //   active: false
    // },
    {
      url: '../../backgrounds/gold.jpeg',
      width: 2250,
      active: false
    },
    // {
    //   url: '../../backgrounds/pattern-triangles.jpg',
    //   width: 4500,
    //   active: false
    // },
    {
      url: '../../backgrounds/red-roses.jpeg',
      width: 2250,
      active: false
    },
    {
      url: '../../backgrounds/teal-stripes.jpeg',
      width: 2254,
      active: false
    },
    {
      url: '../../backgrounds/bokeh-glow.jpeg',
      width: 1880,
      active: false
    },
    {
      url: '../../backgrounds/waves.jpeg',
      width: 2247,
      active: false
    },
    {
      url: '../../backgrounds/fall.jpg',
      width: 2250,
      active: false
    },
    {
      url: '../../backgrounds/antelope.jpeg',
      width: 2250,
      active: false
    },
    {
      url: '../../backgrounds/milky-way.jpeg',
      width: 2520,
      active: false
    },
    {
      url: '../../backgrounds/bokeh.jpeg',
      width: 2250,
      active: false
    },
    // {
    //   url: '../../backgrounds/white-pattern.jpeg',
    //   width: 3370,
    //   active: false
    // },
    {
      url: '../../backgrounds/white-abstract.jpeg',
      width: 2265,
      active: false
    },
    {
      url: '../../backgrounds/wooden-board.jpeg',
      width: 2254,
      active: false
    }
  ]);
  const transformData = () => {
    setIsLoading(true);
    setTimeout(() => {
      if (file[0] === 'sample') {
        fetch('../../sample-audio/10.mp3')
          .then(response => response.arrayBuffer())
          .then(buffer => {
            file = ['sample']; // push dummy item in files object so that background function thinks there is already data
            renderWave(buffer.slice(0));
          });
      } else {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file[0]);
        reader.onload = () => {
          const tmp = reader.result;
          renderWave(tmp.slice(0));
        };
      }
    }, 750);
  };
  const renderWave = arrayBuffer => {
    if (file[0] === 'sample') {
      setFilename('Sample File');
    } else {
      setFilename(file[0].name);
    }
    const selectedImage = backgrounds.filter(d => d.active);
    const img = new Image();
    img.width = 960;
    img.height = 480;
    img.src = selectedImage.length ? selectedImage[0].url : backgrounds[0].url;
    img.onload = () => {
      const ele = document.getElementById('wave-art');
      ele.innerHTML = '';
      // ele.setAttribute("style", `max-width:${selectedImage[0].width}px`)
      setDownloadUrl(null);
      const wavesurfer = WaveSurfer.create({
        container: `#wave-art`,
        config: { img },
        barHeight: 1.15,
        hideScrollbar: true,
        interact: false,
        height: 480
        // maxCanvasWidth: 10000
      });
      wavesurfer.loadArrayBuffer(arrayBuffer);
      setTimeout(() => {
        setDownloadUrl(
          document
            .querySelectorAll('#wave-art > wave > canvas')[0]
            .toDataURL('image/png')
            .replace('image/png', 'image/octet-stream')
        );
      }, 500);
      setIsLoading(false);
    };
  };
  const handleSelection = index => {
    const tmp = backgrounds.map(d => {
      d.active = false;
      return d;
    });
    tmp[index].active = true;
    // eslint-disable-next-line
    setBackgrounds(tmp);
    if (file.length) {
      transformData();
    }
  };
  const openFullscreen = () => setFullscreen(true);
  const closeFullscreen = () => setFullscreen(false);
  const props = {
    name: 'file',
    multiple: false,
    beforeUpload: f => {
      file = [f];
      transformData();
      return false;
    }
  };
  return (
    <div className={'create-route' + (fullscreen ? ' blur' : '')}>
      <Steps direction="vertical" current={-1}>
        <Step
          title="Please select any one of the pattern."
          description={
            <div className="backgrounds-wrapper">
              {backgrounds.map((background, index) => (
                <div
                  className={'box ' + (!background.active ? '' : 'active')}
                  key={background.url}
                  style={{ backgroundImage: `url(${background.url})` }}
                  onClick={() => handleSelection(index)}
                ></div>
              ))}
            </div>
          }
        />
        <Step
          title="Upload/select an audio file"
          description={
            <>
              <div className="wrapper">
                <Dragger {...props}>
                  <p className="ant-upload-drag-icon">
                    <Icon type="inbox" />
                  </p>
                  <p className="ant-upload-text">Click or drag your audio here to upload</p>
                </Dragger>
                <p
                  style={{
                    textAlign: 'center',
                    margin: 0
                  }}
                >
                  or use
                  <Button
                    type="link"
                    onClick={() => {
                      setIsLoading(true);
                      setTimeout(() => {
                        fetch('../../sample-audio/10.mp3')
                          .then(response => response.arrayBuffer())
                          .then(buffer => {
                            file = ['sample']; // push dummy item in files object so that background function thinks there is already data
                            renderWave(buffer.slice(0));
                          });
                      }, 500);
                    }}
                  >
                    Sample audio
                  </Button>
                </p>
                {!!isLoading && (
                  <div className="loader">
                    <Spin tip="Loading...">
                      <Alert message="Please wait until we process your audio." type="info" />
                    </Spin>
                  </div>
                )}
              </div>
            </>
          }
        />
      </Steps>
      <div className="scroll-wrapper">
        <div id="wave-art" className={'wave-wrapper' + (!isLoading ? '' : ' hide')}></div>
      </div>
      <p
        style={{
          textAlign: 'center'
        }}
      >
        {filename}
      </p>
      <div className="button-row">
        <Button type="primary" className={downloadUrl ? '' : 'hide'}>
          <a download="waveart.png" href={downloadUrl}>
            Download
          </a>
        </Button>
        <Button
          type="primary"
          icon="fullscreen"
          className={downloadUrl ? '' : 'hide'}
          onClick={openFullscreen}
        >
          Fullscreen
        </Button>
      </div>
      {fullscreen && (
        <div className="fullscreen">
          <Icon type="fullscreen-exit" onClick={closeFullscreen} />
          <img src={downloadUrl} alt="" />
        </div>
      )}
    </div>
  );
}

export default WLCreate;
